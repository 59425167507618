import React, { useContext } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    Form,
    Row,
    Select,
    // Checkbox,
    Typography,
    message,
} from 'antd';
import useFranchisesLoader from 'components/hooks/ProgramaRecompensa/Promociones/useFranchisesLoader';
import useNewPromBanner from 'components/hooks/useNewPromBanner';
import filterOption from 'components/utils-components/filterOption';
import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';
import UtilidadesContext from 'context/utilidades/UtilidadesContext';
import styles from './CombineCharacteristics.module.css';

const { Option } = Select;
const { Text } = Typography;

const CombineCharacteristics = () => {
    const formInstance = Form.useFormInstance();
    const { characteristics, setCharacteristics, selectedFranchises } =
        useContext(PromotionsContext);
    const { families } = useFranchisesLoader();
    const { franchiseCategoriesByFamily } = useContext(UtilidadesContext);
    const {
        franchises,
        branches,
        obtainingBranches,
        obtainingCategories,
        obtainingFranchises,
        selectedFamily,
        selectedFranchiseCategory,

        handleFamilyChange,
        handleFranchiseChange,
        handleBranchChange,
        handleRemove,
        getTextOption,
        handleCategoryChange,
    } = useNewPromBanner();

    const onAddCharacteristics = () => {
        const values = formInstance.getFieldsValue([
            'family',
            'franchisesCategory',
            'franchises',
            'branches',
        ]);

        let newBranches;

        const isValueAll =
            (values && values.branches[0] === 'all') ||
            values.branches === 'all';

        if (isValueAll) {
            newBranches = branches?.map(
                (branch) => `${branch?.id}|${branch?.name}`,
            );
        } else {
            newBranches = values?.branches;
        }

        const newCharacteristics = {
            family: values?.family,
            franchisesCategory: values?.franchisesCategory,
            franchises: values?.franchises,
            branches: newBranches,
            displayBranches: isValueAll
                ? 'Todas'
                : values?.branches?.length > 1
                ? 'Varias'
                : null,
        };

        const repeatedBranches = characteristics?.filter(
            (characteristic) =>
                Array.isArray(characteristic.branches) &&
                newCharacteristics.branches.some((branch) => {
                    const branchId = branch.split('|')[0];
                    return characteristic.branches.some(
                        (characteristicBranch) =>
                            characteristicBranch.split('|')[0] === branchId,
                    );
                }),
        );

        if (repeatedBranches?.length > 0) {
            message.error('La sucursal ya fue seleccionada.');
            return;
        }

        const addedCharacteristics = (
            Array.isArray(characteristics) ? characteristics : []
        ).concat(newCharacteristics);

        setCharacteristics(addedCharacteristics);

        formInstance.resetFields([
            'franchisesCategory',
            'franchises',
            'branches',
        ]);
    };

    const disabled =
        !formInstance.getFieldValue('family') ||
        !formInstance.getFieldValue('franchisesCategory') ||
        !formInstance.getFieldValue('franchises') ||
        !formInstance.getFieldValue('branches');

    return (
        <>
            <Card
                type="inner"
                title="Combinar características"
                className={styles.card}>
                {/* <Row>
                    <Form.Item name="onlinePay" valuePropName="checked">
                        <Checkbox
                            disabled={disabled}
                            className={styles.checkboxOption}
                            key="onlinePay">
                            Pago online
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name="monchisDriver" valuePropName="checked">
                        <Checkbox
                            disabled={disabled}
                            className={styles.checkboxOption}
                            key="monchisDriver">
                            Driver Monchis
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name="exclusiveLocal" valuePropName="checked">
                        <Checkbox
                            disabled={disabled}
                            className={styles.checkboxOption}
                            key="exclusiveLocal">
                            Local exclusivo
                        </Checkbox>
                    </Form.Item>
                </Row> */}

                <Row className={styles.characteristicsRow}>
                    <Form.Item
                        label="Familia"
                        name="family"
                        className={styles.characteristicsFilter}>
                        <Select
                            placeholder="Seleccionar"
                            loading={!families?.length}
                            disabled={!families?.length}
                            onChange={handleFamilyChange}>
                            {families?.map((family) => (
                                <Option
                                    key={family?.id}
                                    value={`${family?.id}| ${family?.name}`}>
                                    {family?.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Categoría de franquicias"
                        name="franchisesCategory"
                        className={styles.characteristicsFilter}>
                        <Select
                            placeholder="Seleccionar"
                            loading={obtainingCategories}
                            onChange={handleCategoryChange}
                            disabled={obtainingCategories || !selectedFamily}>
                            {franchiseCategoriesByFamily?.map((category) => (
                                <Option
                                    key={category?.id}
                                    value={`${category?.id}|${category?.name}`}>
                                    {category?.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Franquicia"
                        name="franchises"
                        className={styles.characteristicsFilter}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            placeholder="Seleccionar"
                            onChange={handleFranchiseChange}
                            loading={obtainingFranchises}
                            disabled={
                                obtainingFranchises ||
                                !selectedFranchiseCategory
                            }>
                            {franchises?.map((franchise) => (
                                <Option
                                    key={franchise?.id}
                                    value={`${franchise?.id}|${franchise?.name}`}>
                                    {franchise?.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="Sucursal"
                        name="branches"
                        className={styles.characteristicsFilter}>
                        <Select
                            showSearch
                            mode="multiple"
                            maxTagCount={1}
                            optionFilterProp="children"
                            filterOption={filterOption}
                            placeholder="Seleccionar"
                            loading={obtainingBranches}
                            disabled={
                                obtainingBranches ||
                                !selectedFranchises ||
                                branches?.length === 0
                            }
                            onChange={handleBranchChange}>
                            {branches?.length > 1 && (
                                <Option value="all">Todas</Option>
                            )}
                            {branches.map((branch) => (
                                <Option
                                    key={branch?.id}
                                    value={`${branch?.id}|${branch?.name}`}>
                                    {branch?.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Row>

                <Row className={styles.marginTop30px}>
                    <Col span={24}>
                        <Button
                            disabled={disabled}
                            type="dashed"
                            block
                            onClick={onAddCharacteristics}
                            icon={<PlusOutlined />}>
                            Agregar características
                        </Button>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        {characteristics?.map((characteristic, index) => (
                            <div
                                key={`${index} ${characteristic}`}
                                className={styles.characteristicsWrapper}>
                                <div
                                    className={
                                        styles.characteristicsTextWrapper
                                    }>
                                    <Text>Familia: </Text>
                                    <Text strong>
                                        {!characteristic?.family
                                            ? 'Todas'
                                            : getTextOption(
                                                  characteristic?.family,
                                              )}
                                    </Text>

                                    <span>|</span>

                                    <Text>Categoría: </Text>
                                    <Text strong>
                                        {!characteristic?.franchisesCategory
                                            ? 'Todas'
                                            : getTextOption(
                                                  characteristic?.franchisesCategory,
                                              )}
                                    </Text>

                                    <span>|</span>

                                    <Text>Franquicia: </Text>
                                    <Text strong>
                                        {!characteristic?.franchises
                                            ? 'Todas'
                                            : getTextOption(
                                                  characteristic?.franchises,
                                              )}
                                    </Text>

                                    <span>|</span>

                                    <Text>Sucursal: </Text>
                                    <Text strong>
                                        {characteristic?.displayBranches
                                            ? characteristic?.displayBranches
                                            : characteristic?.branches[0]?.split(
                                                  '|',
                                              )[1] ||
                                              getTextOption(
                                                  characteristic?.branches,
                                              )}
                                    </Text>
                                </div>

                                <MinusCircleOutlined
                                    onClick={() => handleRemove(index)}
                                    className={styles.removeButton}
                                />
                            </div>
                        ))}
                    </Col>
                </Row>
            </Card>
        </>
    );
};

export default CombineCharacteristics;
