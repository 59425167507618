import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Form,
    Input,
    List,
    Modal,
    Col,
    Row,
    Select,
    Alert,
} from 'antd';
import ModalContent from 'components/atoms/ModalContent';
import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';
import styles from './AddProductModal.module.css';
import './productModal.css';
import PropTypes from 'prop-types';
import filterOption from 'components/utils-components/filterOption';
import { useHandleProductModal } from 'components/hooks/ProgramaRecompensa/Promociones/useHandleProductModal';
import { ProductsList } from 'components/atoms/ProgramaRecompensas/Promociones/BannersPromo/ProductsList';
import { ProductsSelectedList } from 'components/atoms/ProgramaRecompensas/Promociones/BannersPromo/ProductsSelectedList';

const { Option } = Select;

const AddProductModal = ({ visible, onSave, onClose }) => {
    const form = Form.useFormInstance();
    const {
        productList,
        productListPageSize,
        productListTotal,
        characteristics,
        selectedProducts,

        setProductList,
        setSelectedProducts,
    } = useContext(PromotionsContext);

    const {
        getOptionsList,
        getUniqueItems,
        getIdList,
        showSelectedEntities,
        getSearchParams,

        onChangeFranchises,
        getProducts,
        getFranchises,

        setSelectedFranchises,
        selectedFranchises,

        setBranches,
        franchises,
        branches,

        franchisesSelected,
        setFranchisesSelected,
        branchesSelected,
        setBranchesSelected,

        loader,
        obtainingFranchises,
        obtainingBranches,
    } = useHandleProductModal(form);

    const [errorItem, setErrorItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const [addedProducts, setAddedProducts] = useState([]);

    useEffect(() => {
        if (!visible) return;

        if (selectedProducts?.length > 0) {
            setAddedProducts(selectedProducts);
        }

        const franchisesSelected = getUniqueItems(
            characteristics?.map((item) => item.franchises) || [],
        );

        const branchesSelected = getUniqueItems(
            characteristics?.flatMap((item) =>
                Array.isArray(item.branches) ? item.branches : [item.branches],
            ) || [],
        );

        setFranchisesSelected(franchisesSelected);
        setBranchesSelected(branchesSelected);

        if (franchisesSelected.length > 0) {
            const franchisesToString = getIdList(franchisesSelected);
            const branchesToString = getIdList(branchesSelected);

            getProducts(
                null,
                franchisesToString,
                branchesToString,
                currentPage,
            );
        } else {
            getFranchises();
        }

        return () => {
            form.resetFields(['productName', 'franchises', 'branches']);
            setErrorItem(null);
            setProductList([]);
            setCurrentPage(1);
            setBranches([]);
            setSelectedFranchises(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const franchisesOptions =
        franchisesSelected?.length > 0
            ? getOptionsList(franchisesSelected)
            : franchises.map((item) => (
                  <Option key={item.id} value={item.id}>
                      {item.name}
                  </Option>
              ));

    const branchesOptions =
        branchesSelected?.length > 0
            ? getOptionsList(branchesSelected)
            : branches.map((item) => (
                  <Option key={item.id} value={item.id}>
                      {item.name}
                  </Option>
              ));

    const okHandler = () => {
        onSave(addedProducts);
    };

    const handleSearch = () => {
        const { productName, franchises, branches, page } = getSearchParams();

        getProducts(productName, franchises, branches, page);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        const { productName, franchises, branches } = getSearchParams(page);

        getProducts(productName, franchises, branches, page);
    };

    const onAddProduct = (product) => {
        let branches = form.getFieldValue('branches') || [];

        const isProductAdded = addedProducts.some(
            (item) =>
                item.franchise_name === product.franchise_name &&
                item.product_name === product.product_name,
        );

        if (isProductAdded) {
            setErrorItem(product);
            return;
        }

        const newProduct = {
            ...product,
            branch_ids: branches,
        };

        const updatedProductsList = [...addedProducts, newProduct];

        setAddedProducts(updatedProductsList);
        setSelectedProducts(updatedProductsList);
    };

    const onRemoveProduct = (product) => {
        const updatedProductsList = addedProducts.filter(
            (item) =>
                item.franchise_name !== product.franchise_name ||
                item.product_name !== product.product_name,
        );
        setAddedProducts(updatedProductsList);
        setSelectedProducts(updatedProductsList);
    };

    return (
        <Modal
            title="Agregar productos"
            open={visible}
            onCancel={onClose}
            footer={null}
            width="65%"
            centered
            className="ant-modal-body">
            <ModalContent
                className="ant-modal-body"
                isLoading={false}
                cancelText="Cancelar"
                onCancel={onClose}
                okText="Guardar"
                onOk={okHandler}>
                <Form form={form} layout="vertical">
                    <Row align="bottom" gutter={[8, 8]}>
                        <Col span={6}>
                            <Form.Item
                                label="Nombre del producto"
                                name="productName">
                                <Input placeholder="Escribí el nombre del producto" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="Franquicia" name="franchises">
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    mode={
                                        franchisesSelected?.length > 0
                                            ? 'multiple'
                                            : null
                                    }
                                    maxTagCount={1}
                                    filterOption={filterOption}
                                    onChange={
                                        franchisesSelected?.length > 1
                                            ? null
                                            : onChangeFranchises
                                    }
                                    disabled={
                                        obtainingFranchises ||
                                        obtainingBranches ||
                                        loader
                                    }
                                    value={
                                        franchisesSelected?.length > 0
                                            ? showSelectedEntities(
                                                  franchisesSelected,
                                              )
                                            : null
                                    }
                                    loading={obtainingFranchises}
                                    placeholder={
                                        franchisesSelected.length > 0
                                            ? showSelectedEntities(
                                                  franchisesSelected,
                                              )
                                            : 'Seleccionar'
                                    }>
                                    {franchisesOptions}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item label="Sucursal" name="branches">
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    mode="multiple"
                                    maxTagCount={1}
                                    disabled={
                                        !branches?.length ||
                                        obtainingBranches ||
                                        loader
                                    }
                                    value={
                                        branchesSelected?.length > 0
                                            ? showSelectedEntities(
                                                  branchesSelected,
                                              )
                                            : null
                                    }
                                    loading={obtainingBranches}
                                    placeholder={
                                        branchesSelected?.length > 0
                                            ? showSelectedEntities(
                                                  branchesSelected,
                                              )
                                            : 'Seleccionar'
                                    }>
                                    {branchesOptions}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Button
                                disabled={
                                    !franchisesSelected.length &&
                                    !selectedFranchises
                                }
                                type="primary"
                                onClick={handleSearch}
                                className={styles.marginBottom}>
                                Buscar
                            </Button>
                        </Col>
                    </Row>
                </Form>

                {visible &&
                    !franchisesSelected.length &&
                    !selectedFranchises && (
                        <Alert
                            className={styles.itemBottom}
                            message="Selecciona una franquicia para buscar productos"
                            type="warning"
                            showIcon
                        />
                    )}

                <List
                    locale={{ emptyText: 'No hay productos para mostrar' }}
                    loading={loader}
                    bordered
                    dataSource={productList}
                    renderItem={(item) => (
                        <ProductsList
                            handleAddProduct={onAddProduct}
                            item={item}
                            errorItem={errorItem}
                        />
                    )}
                    pagination={{
                        position: 'bottom',
                        align: 'right',
                        showSizeChanger: false,
                        pageSize: productListPageSize || 15,
                        total: productListTotal,
                        onChange: handlePageChange,
                        hideOnSinglePage: true,
                    }}
                />

                <List
                    className={styles.addedProductBox}
                    bordered
                    locale={{
                        emptyText: 'Aun no se agregaron productos a la lista',
                    }}
                    dataSource={addedProducts}
                    renderItem={(item) => (
                        <ProductsSelectedList
                            onRemoveProduct={onRemoveProduct}
                            item={item}
                        />
                    )}
                    pagination={{
                        position: 'bottom',
                        align: 'right',
                        showSizeChanger: false,
                        pageSize: 5,
                        hideOnSinglePage: true,
                    }}
                />
            </ModalContent>
        </Modal>
    );
};

export default AddProductModal;

AddProductModal.propTypes = {
    visible: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
};
