import { useState, useContext } from 'react';
import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';
import UtilidadesContext from 'context/utilidades/UtilidadesContext';
import getBase64 from 'components/utils-components/getBase64';
import { message } from 'antd';

const extractUniqueIds = (characteristics, key) => {
    if (!Array.isArray(characteristics)) return [];

    return [
        ...new Set(
            characteristics.flatMap((characteristic) => {
                const value = characteristic[key];
                if (Array.isArray(value)) {
                    return value.map((item) =>
                        parseInt(item.split('|')[0], 10),
                    );
                } else if (typeof value === 'string') {
                    return parseInt(value.split('|')[0], 10);
                }
                return [];
            }),
        ),
    ];
};

const useNewPromBanner = () => {
    const {
        obtenerSucursales,
        getFranchiseCategoriesByFamily,
        obtenerFranquicias,
    } = useContext(UtilidadesContext);

    const {
        createOrEditPromo,
        characteristics,
        setCharacteristics,
        selectedProducts,
        setSelectedFranchises,
        setSelectedBranches,
    } = useContext(PromotionsContext);

    const [selectedFamily, setSelectedFamily] = useState(null);
    const [selectedFranchiseCategory, setSelectedFranchiseCategory] =
        useState(null);
    const [franchises, setFranchises] = useState(null);
    const [branches, setBranches] = useState([]);

    const [obtainingBranches, setObtainingBranches] = useState(false);
    const [obtainingCategories, setObtainingCategories] = useState(false);
    const [obtainingFranchises, setObtainingFranchises] = useState(false);

    const [previousFamily, setPreviousFamily] = useState(null);
    const [previousCategory, setPreviousCategory] = useState(null);
    const [previousFranchise, setPreviousFranchise] = useState(null);
    const [previousBranches, setPreviousBranches] = useState([]);

    const initialValues = { franchisesCategory: [], branches: [] };

    const handleCreateOrEditBanner = async (data, id) => {
        let imageToBase64;

        if (data.image[0].originFileObj) {
            imageToBase64 = await getBase64(data.image[0].originFileObj);
        } else {
            imageToBase64 = null;
        }

        const formatDate = (date) =>
            date ? date.toISOString(true).slice(0, 10) : null;

        const formatTime = (time) => {
            if (!time) return null;
            const date = new Date(time);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${hours}:${minutes}:${seconds}`;
        };

        const dayOfWeek = {
            monday: 1,
            tuesday: 2,
            wednesday: 3,
            thursday: 4,
            friday: 5,
            saturday: 6,
            sunday: 0,
        };

        const programation = Array.isArray(data?.scheduled)
            ? data.scheduled.map((day) => ({
                  time_from: formatTime(day.promotionHourFrom),
                  time_to: formatTime(day.promotionHourTo),
                  week_day: dayOfWeek[day.promotionDay],
              }))
            : [];

        const details = {
            branch_id: characteristics?.length
                ? extractUniqueIds(characteristics, 'branches')
                : [
                      ...new Set(
                          selectedProducts?.flatMap(
                              (product) => product.branch_ids || [],
                          ),
                      ),
                  ],
            franchise_id: characteristics?.length
                ? extractUniqueIds(characteristics, 'franchises')
                : [
                      ...new Set(
                          selectedProducts?.flatMap(
                              (product) => product.franchise_id || [],
                          ),
                      ),
                  ],
            franchise_family_id: characteristics?.length
                ? extractUniqueIds(characteristics, 'family')
                : [],
            franchise_category_id: characteristics?.length
                ? extractUniqueIds(characteristics, 'franchisesCategory')
                : [],
            audiencie_id: data?.audience ? parseInt(data?.audience) : null,
            zone_ids: data?.zonasCobertura?.length
                ? data?.zonasCobertura[0].zone_ids
                : null,
            city_id: data?.ciudad ? parseInt(data?.ciudad) : null,
            neighborhood_id: data?.barrio ? parseInt(data?.barrio) : null,
            points: data?.poligonozonageografica
                ? data?.poligonozonageografica
                : null,
        };

        if (details.points && details.points.length > 0) {
            const firstPoint = details.points[0];
            details.points.push(firstPoint);
        }

        const selectedProductsIds =
            selectedProducts?.map((product) => product.product_id) || [];

        const products = {
            ids: selectedProductsIds,
        };

        const dataBody = {
            name: data?.promotionName,
            show: data?.showAs,
            show_in_home: data?.showHome,

            // has_bancard: data?.onlinePay,
            // has_monchis_driver: data?.monchisDriver,
            // exclusive: data?.exclusiveLocal,

            valid_from: formatDate(data?.promotionDateFrom),
            valid_to: formatDate(data?.promotionDateTo),

            programation: programation,
            products: products,
            details: details,

            banner_img: imageToBase64,
            promotion_image: imageToBase64,
        };

        return await createOrEditPromo(dataBody, id);
    };

    const handleFamilyChange = (value) => {
        const id = parseInt(value.split('|')[0]);

        if (previousFamily === id) {
            return;
        }
        setPreviousFamily(id);

        setObtainingCategories(true);
        setSelectedFamily(value);
        getFranchiseCategoriesByFamily([id]).then(() => {
            setObtainingCategories(false);
        });
    };

    const handleCategoryChange = (value) => {
        const id = parseInt(value.split('|')[0]);

        if (previousCategory === id) {
            return;
        }
        setPreviousCategory(id);

        setObtainingFranchises(true);
        setSelectedFranchiseCategory(value);
        obtenerFranquicias(id).then((response) => {
            const filteredFranchises = response.filter(
                (franchise) => !franchise.name.includes('CERRADO'),
            );
            setFranchises(filteredFranchises);
            setObtainingFranchises(false);
        });
    };

    const handleFranchiseChange = (value, option) => {
        const id = value.split('|')[0];

        if (previousFranchise === id) {
            return;
        }
        setPreviousFranchise(id);

        const selectedFranchise = { id, name: option.children };
        getBranches(id);
        setSelectedFranchises(selectedFranchise);
    };

    const handleBranchChange = (value, option) => {
        const currentBranchIds = Array.isArray(value)
            ? value.map((v) => v.split('|')[0])
            : [value.split('|')[0]];

        if (
            currentBranchIds.length === previousBranches.length &&
            currentBranchIds.every((id) => previousBranches.includes(id))
        ) {
            message.warning('la sucursal ya esta seleccionada.');
            return;
        }

        setPreviousBranches(currentBranchIds);

        const selectedBranches = currentBranchIds.map((id, index) => ({
            id,
            name: Array.isArray(option)
                ? option[index]?.children
                : option.children,
        }));

        setSelectedBranches(selectedBranches);
    };

    const getBranches = async (id) => {
        setObtainingBranches(true);
        const branches = await obtenerSucursales(id);
        const filteredBranches = branches.filter(
            (branch) => !branch.name.includes('CERRADO'),
        );
        setBranches(filteredBranches);
        setObtainingBranches(false);
    };

    const handleRemove = (index) => {
        const copyOfTheCharacteristics = structuredClone(characteristics);
        delete copyOfTheCharacteristics[index];
        const characteristicsWithoutUndefinedValue =
            copyOfTheCharacteristics.filter(
                (characteristic) => characteristic !== undefined,
            );
        setCharacteristics([...characteristicsWithoutUndefinedValue]);
    };

    const getTextOption = (text) => {
        if (Array.isArray(text) || text === 'all') return 'Todas';

        return text.split('|')[1];
    };

    return {
        franchises,
        branches,
        obtainingBranches,
        obtainingCategories,
        obtainingFranchises,
        characteristics,
        initialValues,
        selectedFamily,
        selectedFranchiseCategory,

        handleCreateOrEditBanner,
        getBranches,
        setObtainingBranches,
        setObtainingCategories,
        setBranches,
        handleFranchiseChange,
        handleFamilyChange,
        handleBranchChange,
        handleRemove,
        getTextOption,
        handleCategoryChange,
        setSelectedFranchiseCategory,
        setSelectedFamily,
    };
};

export default useNewPromBanner;
