import React from 'react';
import { Button, List, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;
export const ProductsSelectedList = ({ onRemoveProduct, item }) => {
    return (
        <List.Item
            actions={[
                <Button
                    key={item.product_name}
                    type="link"
                    danger
                    onClick={() => onRemoveProduct(item)}>
                    Eliminar
                </Button>,
            ]}>
            <List.Item.Meta
                title={<Text>{item.product_name}</Text>}
                description={<Text>{item.franchise_name}</Text>}
            />
        </List.Item>
    );
};

ProductsSelectedList.propTypes = {
    onRemoveProduct: PropTypes.func,
    item: PropTypes.object,
};
