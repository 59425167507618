import { useContext, useEffect, useMemo, useState } from 'react';

import UtilidadesContext from 'context/utilidades/UtilidadesContext';

const useFranchisesLoader = () => {
    const { obtenerFamilias } = useContext(UtilidadesContext);

    const [families, setFamilies] = useState([]);

    useEffect(() => {
        if (!families.length) {
            obtenerFamilias().then((response) => setFamilies(response));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const memoFamilies = useMemo(() => families, [families]);

    return {
        families: memoFamilies,
    };
};

export default useFranchisesLoader;
