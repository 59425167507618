import React from 'react';
import { Button, List, Alert, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

export const ProductsList = ({ handleAddProduct, item, errorItem }) => {
    return (
        <>
            <List.Item
                actions={[
                    <Button
                        key={item.product_name}
                        type="link"
                        onClick={() => {
                            handleAddProduct(item);
                        }}>
                        Agregar
                    </Button>,
                ]}>
                <List.Item.Meta
                    title={<Text>{item.product_name}</Text>}
                    description={<Text>{item.franchise_name}</Text>}
                />
            </List.Item>
            {errorItem &&
                errorItem.product_name === item.product_name &&
                errorItem.franchise_name === item.franchise_name && (
                    <Alert
                        message="El producto ya fue agregado"
                        type="error"
                        showIcon
                    />
                )}
        </>
    );
};

ProductsList.propTypes = {
    handleAddProduct: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    errorItem: PropTypes.object,
};
