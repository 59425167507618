import React, { useContext, useState } from 'react';
import { Button, Card, List, Typography, Empty, Row, Col, Form } from 'antd';
import styles from './SelectProducts.module.css';
import AddProductModal from './AddProductModal';
import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';

const { Text } = Typography;

const SelectProducts = () => {
    const formInstance = Form.useFormInstance();

    const [addProductsModalVisible, setaddProductsModalVisible] =
        useState(false);
    const { selectedProducts } = useContext(PromotionsContext);

    const ItemsSelected = (item) => (
        <>
            <List.Item>
                <List.Item.Meta
                    title={<Text>{item.product_name}</Text>}
                    description={<Text>{item.franchise_name}</Text>}
                />
            </List.Item>
        </>
    );

    const handleOpenCloseModal = () => {
        setaddProductsModalVisible(!addProductsModalVisible);
    };

    const EmptyText = () => {
        return (
            <div className={styles.empty}>
                <Empty description={false} />
                <Text strong>Ningún producto especificado.</Text>
                <p>
                    Para personalizar la selección, dale clic al siguiente
                    botón.
                </p>
            </div>
        );
    };

    const updateSelectedProducts = (selectedId) => {
        const selectedIdString = selectedId.toString();
        formInstance.setFieldValue({ selectedProducts: selectedIdString });
        handleOpenCloseModal(true);
    };

    return (
        <Card
            type="inner"
            title="Seleccionar productos"
            className={styles.card}>
            <Form.Item name="selectedProducts" hidden />
            <Row>
                <Col className={styles.itemsSelected}>
                    <div>
                        <List
                            locale={{ emptyText: <EmptyText /> }}
                            dataSource={selectedProducts}
                            renderItem={ItemsSelected}></List>
                    </div>
                    <div className={styles.alignCenter}>
                        <Button onClick={handleOpenCloseModal}>
                            Agregar productos
                        </Button>
                    </div>
                </Col>
            </Row>

            <AddProductModal
                visible={addProductsModalVisible}
                onSave={updateSelectedProducts}
                onClose={handleOpenCloseModal}
            />
        </Card>
    );
};

export default SelectProducts;
