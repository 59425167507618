import React, { useContext, useState } from 'react';
import { Select } from 'antd';
import UtilidadesContext from 'context/utilidades/UtilidadesContext';
import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';
const { Option } = Select;

export const useHandleProductModal = (searchForm) => {
    const { obtenerFranquicias, obtenerSucursales } =
        useContext(UtilidadesContext);

    const { getProductsList } = useContext(PromotionsContext);

    const [selectedFranchises, setSelectedFranchises] = useState(false);

    const [loader, setLoader] = useState();
    const [obtainingFranchises, setObtainingFranchises] = useState(false);
    const [obtainingBranches, setObtainingBranches] = useState(false);

    const [franchises, setFranchises] = useState([]);
    const [branches, setBranches] = useState([]);

    const [franchisesSelected, setFranchisesSelected] = useState([]);
    const [branchesSelected, setBranchesSelected] = useState([]);

    const getOptionsList = (list) =>
        list.map((item) => {
            const [id, name] = item.split('|');
            return (
                <Option key={id} value={id}>
                    {name}
                </Option>
            );
        });

    const getUniqueItems = (list) => Array.from(new Set(list.filter(Boolean)));

    const getIdList = (list) =>
        list.map((item) => item.split('|')[0]).join(',');

    const showSelectedEntities = (selectedEntities) =>
        selectedEntities?.map((item) => item.split('|')[1]);

    const onChangeFranchises = async (id) => {
        searchForm.resetFields(['branches']);

        setSelectedFranchises(true);
        setObtainingBranches(true);

        try {
            const branches = await obtenerSucursales(id);
            setBranches(branches);
        } catch (error) {
            console.error('Error fetching branches:', error);
        } finally {
            setObtainingBranches(false);
        }
    };

    const getProducts = (productName, franchises, branches, page) => {
        setLoader(true);
        getProductsList(productName, franchises, branches, page)
            .then(() => {
                setLoader(false);
            })
            .catch((error) => {
                console.error(error);
                setLoader(false);
            });
    };

    const getFranchises = async () => {
        setObtainingFranchises(true);
        obtenerFranquicias()
            .then((response) => {
                const filteredFranchises = response.filter(
                    (franchise) => !franchise.name.includes('CERRADO'),
                );
                setFranchises(filteredFranchises);
            })
            .catch((error) => {
                console.error('Error fetching franchises:', error);
            })
            .finally(() => setObtainingFranchises(false));
    };

    const getSearchParams = (page = 1) => {
        const productName = searchForm.getFieldValue('productName') || null;
        let franchises;
        let branches;

        if (franchisesSelected.length > 0) {
            franchises = getIdList(franchisesSelected);
            branches = getIdList(branchesSelected);
        } else {
            const values = searchForm.getFieldsValue([
                'franchises',
                'branches',
            ]);
            franchises = Array.isArray(values.franchises)
                ? values.franchises.join(',')
                : values.franchises;
            branches = Array.isArray(values.branches)
                ? values.branches.join(',')
                : null;
        }

        return { productName, franchises, branches, page };
    };

    return {
        getOptionsList,
        getUniqueItems,
        getIdList,
        showSelectedEntities,
        getSearchParams,

        getProducts,
        getFranchises,

        onChangeFranchises,

        setSelectedFranchises,
        selectedFranchises,

        setBranches,
        franchises,
        branches,

        setFranchisesSelected,
        franchisesSelected,
        setBranchesSelected,
        branchesSelected,

        loader,
        obtainingFranchises,
        obtainingBranches,
    };
};
